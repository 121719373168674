// Queries and mutations specifically for the Classes Scene
import { gql } from 'apollo-boost';


export const GET_YEAR_STATS = gql`
    query schoolYearStats($schoolId: ID!) {
        school(schoolId: $schoolId) {
            id
            currentAcademicYear
            newYearStep
            yearGroupCounters {
                id
                studentCounter
                yearGroup
            }
            duplicatedStudentsData {
                id
                name
                counter
                yearGroup
                students {
                    id
                    name
                    yearGroup
                    upn
                    firstName
                    lastName
                    loginCode
                    classes {
                        id
                        name
                        typeId
                        archived
                    }
                }
            }
            studentsWithoutUpnCounter
            unassignedStudentsCounter
        }
    }
`;

export const GET_ARCHIVED_STUDENTS = gql`
    query archivedStudents($schoolId: ID!) {
        school(schoolId: $schoolId) {
            id
            inactiveStudents {
                id
                active
                firstName
                lastName
                upn
                updatedAt
                boomerAvatarUrl
                yearGroup
                classes {
                    id
                    name
                    typeId
                    archived
                }
                schoolMemberships(active: false) {
                    id
                    removedAt
                }
            }
        }
    }
`


export const GET_FILTERED_STUDENTS = gql`
    query schoolFilteredStudents($schoolId: ID!, $yearGroup: String, $withoutUpn: Boolean) {
        school(schoolId: $schoolId) {
            id
            filteredStudents(yearGroup: $yearGroup, withoutUpn: $withoutUpn) {
                id
                active
                firstName
                lastName
                upn
                updatedAt
                boomerAvatarUrl
                yearGroup
                loginCode
                classes {
                    id
                    name
                    typeId
                    archived
                }
            }
        }
    }
`;

export const GET_UNASSIGNED_STUDENTS_QUERY = gql`
query schoolUnassignedStudents($schoolId: ID!) {
    school(schoolId: $schoolId) {
        id
        studentsWithoutYearGroup {
            id
            firstName
            lastName
            upn
            updatedAt
            boomerAvatarUrl
            loginCode
            classes {
                id
                name
                typeId
                archived
            }
        }
        unassignedStudents {
            id
            firstName
            lastName
            upn
            updatedAt
            boomerAvatarUrl
            yearGroup
            loginCode
            classes {
                id
                name
                typeId
                archived
            }
        }
    }
}
`;


export const SEARCH_STUDENTS = gql`
query schoolSearchStudents($schoolId: ID!, $nameOrUpn: String!) {
    school(schoolId: $schoolId) {
        id
        searchStudentsByNameOrUpn(nameOrUpn: $nameOrUpn) {
            id
            firstName
            lastName
            avatarJson
            updatedAt
            boomerAvatarUrl
            classes {
                id
                name
                typeId
            }
        }
    }
}
`;

export const GET_STUDENT = gql`
query studentById($studentId: String!) {
    studentById(studentId: $studentId) {
        id
        firstName
        lastName
        displayName
        email
        yearGroup
        avatarJson
        updatedAt
        boomerAvatarUrl
        loginCode
        upn
        school {
            id
            schoolCode
            canAccessGoReadApp
        }
        classes {
            id
            name
            typeId
            teachers {
                id
                displayName
            }
        }
    }
}
`;

export const REGENERATE_LOGIN_CODE = gql`
mutation regenerateStudentLoginCode($studentId: ID!) {
    regenerateStudentLoginCode(studentId: $studentId) {
        student {
            id
            loginCode
        }
        errors
    }
}
`;

export const EDIT_STUDENT = gql`
mutation editStudent($schoolId: ID!, $studentId: ID!, $yearGroup: Int!, $firstName: String!, $lastName: String!, $email: String!, $upn: String, $displayName: String!) {
    editStudent(schoolId: $schoolId, studentId: $studentId,yearGroup: $yearGroup, firstName: $firstName, lastName: $lastName, email: $email, upn: $upn, displayName: $displayName) {
        student {
            id
            name
            firstName
            lastName
            loginCode
            yearGroup
            upn
        }
        errors
    }
}
`;



export const ADD_STUDENT_MUTATION = gql`
mutation addStudent($schoolId: ID!,$firstName: String!, $lastName: String!, $displayName: String!, $email: String!, $yearGroup: Int!, $upn: String) {
    addStudent(schoolId: $schoolId, firstName: $firstName, lastName: $lastName, displayName: $displayName, email: $email, yearGroup: $yearGroup, upn: $upn) {
        student {
            id
            firstName
            lastName
            yearGroup
            upn
        }
        errors
    }
}`;


export const MERGE_STUDENTS = gql`
mutation mergeStudentAccounts($student1Id: ID!, $student2Id: ID!) {
    mergeStudentAccounts(student1Id: $student1Id, student2Id: $student2Id) {
        merged
        student {
            id
        }
        errors
    }
}
`

export const REMOVE_STUDENT_FROM_CLASS = gql`
mutation removeStudentFromClass($studentId: ID!, $studentsClassId: ID!) {
    removeStudentFromClass(studentId: $studentId, studentsClassId: $studentsClassId) {
        deleted
        errors
    }
}
`

export const REACTIVATE_STUDENTS = gql`
mutation activateSchoolMemberships($studentIds: [ID!]!) {
    activateSchoolMemberships(studentIds: $studentIds) {
        success
        errors
    }
}
`

export const GET_CLASSES_LIST_QUERY = gql`
query school($schoolId: ID!) {
    school(schoolId: $schoolId) {
        id
        classes {
            id
            name
        }
    }
}
`;

export const REMOVE_STUDENTS_FROM_SCHOOL = gql`
mutation removeStudentsFromSchool($studentIds: [ID!]!, $schoolId: ID!, $is6YearLeaver: Boolean) {
    removeStudentsFromSchool(studentIds: $studentIds, schoolId: $schoolId, is6YearLeaver: $is6YearLeaver) {
        students {
            id
        }
        errors
    }
}`;
import React, { useState, useEffect, useContext, useRef, Fragment } from 'react'
import { GET_YEAR_STATS, GET_FILTERED_STUDENTS, GET_UNASSIGNED_STUDENTS_QUERY, SEARCH_STUDENTS, REACTIVATE_STUDENTS, GET_ARCHIVED_STUDENTS, REMOVE_STUDENTS_FROM_SCHOOL } from './services/graphql';
import { useQuery, useLazyQuery, useMutation, } from '@apollo/react-hooks';
import { AuthContext } from '../../services/auth/AuthProvider';
import styles from './Students.module.scss'
import cx from 'classnames'
import { YEAR_GROUPS } from '@axeedge/go-shared-utils';
import loader from '../../images/loader.svg';
import boomer from '../../images/loader-boomer.png'
import * as Icon from 'react-feather'
import PageHeader from '../../components/PageHeader';
import { Link } from '@reach/router'
import Student from './components/Student'
import AddStudent from './components/AddStudent'
import Duplicate from './components/Duplicate'
import MoveClasses from './components/MoveClasses'
import MoveYear from './components/MoveYear'
import ArchiveStudents from './components/ArchiveStudents';
import MergeStudents from './components/MergeStudents';
import { Button, Modal } from '@axeedge/go-teacher-components';
import NewSchoolYearWarning from '../../components/NewSchoolYearWarning';
import { getYearGroupLabel } from '../../services/utils';
import moment from 'moment';
import {
    Tooltip,
} from 'react-tippy';
import 'react-tippy/dist/tippy.css'
import { useTranslation } from 'react-i18next';

const Students = ({ source }) => {
    const { t } = useTranslation(['classes', 'common']);

    const { currentUser: user } = useContext(AuthContext);

    const [currentYear, setCurrentYear] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [results, setResults] = useState([]);
    const [noStudents, setNoStudents] = useState(false);
    const [currentStudent, setStudent] = useState(null)
    const intervalRef = useRef(null);
    const [showAdd, setShowAdd] = useState(false)
    const [selectedStudents, setSelectedStudents] = useState([])
    const [selectAll, setSelectAll] = useState(false)

    const [showMoveClass, setShowMoveClass] = useState(false)
    const [showMoveYear, setShowMoveYear] = useState(false)
    const [showArchive, setShowArchive] = useState(false)
    const [showMerge, setShowMerge] = useState(false)
    const [stToMerge, setStToMerge] = useState([]);
    const [processing, setProcessing] = useState(false)

    const { data, error, loading } = useQuery(GET_YEAR_STATS, {
        variables: {
            schoolId: user && user.school.id
        },
        fetchPolicy: 'network-only'
    });

    const [searchStudents, { loading: searchLoading, error: searchError }] = useLazyQuery(SEARCH_STUDENTS, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data && data.school.searchStudentsByNameOrUpn) {
                setResults(data.school.searchStudentsByNameOrUpn);
                if (data.school.searchStudentsByNameOrUpn.length === 0) {
                    setNoStudents(true)
                } else {
                    setNoStudents(false)
                }
            }
        }
    });

    const [getSchoolStudentList, { data: filteredData, error: filteredError, loading: filteredLoading }] = useLazyQuery(GET_FILTERED_STUDENTS, {
        fetchPolicy: "network-only",
    });

    const [getArchivedList, { data: archivedData, error: archivedError, loading: archivedLoading }] = useLazyQuery(GET_ARCHIVED_STUDENTS, {
        fetchPolicy: "network-only",
    });

    const [getUnassignedStudentList, { data: unassignedData, error: unassignedError, loading: unassignedLoading }] = useLazyQuery(GET_UNASSIGNED_STUDENTS_QUERY, {
        fetchPolicy: "network-only",
    });

    const getYearGroupName = yearGroup => {
        const yGroup = YEAR_GROUPS.find(yg => yg.val === yearGroup);
        return yGroup ? yGroup.name : '-'
    }

    const [restoreStudents, { loading: restoreLoading, error: restoreError }] = useMutation(REACTIVATE_STUDENTS, {
        awaitRefetchQueries: true,
        onCompleted: (data) => {
            if (data.activateSchoolMemberships.errors && data.activateSchoolMemberships.errors.length > 0) {
                console.log("error from backend?")
            } else {
                setSelectAll(false)
                setSelectedStudents([])
            }
            return
        },
        onError: (error) => {
            console.log("error")
            return;
        },
        refetchQueries: [
            {
                query: GET_ARCHIVED_STUDENTS,
                variables: {
                    schoolId: user && user.school.id,
                }
            },
            {
                query: GET_YEAR_STATS,
                variables: {
                    schoolId: user && user.school.id,
                }
            }
        ],
    });


    const onSelectStudent = (e, student) => {
        if (selectedStudents.indexOf(e.target.value) !== -1) {
            setSelectedStudents(selectedStudents.filter(s => s !== e.target.value));
            setStToMerge(stToMerge.filter(s => s.id !== e.target.value));
        } else {
            setSelectedStudents([...selectedStudents, e.target.value]);
            setStToMerge([...stToMerge, student]);
        }
    }


    const studentItem = (s) => {
        const regClasses = s.classes.filter(x => x.typeId === 1);
        return (
            <li className={styles.studentItem} key={`s${s.id}`}>
                <div className={styles.studentItemMeta}>
                    <input value={s.id} type="checkbox" checked={selectedStudents.indexOf(s.id) > -1} onChange={(e) => onSelectStudent(e, s)} />
                    <div className='u-m-left-2'>
                        {currentYear === 'INACTIVE' ?
                            <>
                                <span className={styles.studentItemNameArchived}>{s.firstName} {s.lastName}</span>
                                <span className={styles.studentYearGroup}>{s.yearGroup && getYearGroupLabel(s.yearGroup)} {s.schoolMemberships[0]?.removedAt && <>- Archived: {moment(s.schoolMemberships[0]?.removedAt).format('DD MMM YYYY')}</>}</span>
                            </>
                            :
                            <>
                                <span onClick={() => setStudent(s)} className={styles.studentItemName}>{s.firstName} {s.lastName}</span>
                                {regClasses.length === 1 ?
                                    <Link to={`/classes/${regClasses[0].id}`} className={styles.className}>{regClasses[0].name}</Link>
                                    :
                                    regClasses.length === 2 ?
                                        <><Link to={`/classes/${regClasses[0].id}`} className={styles.className}>{regClasses[0].name}</Link> and <Link to={`/classes/${regClasses[1].id}`} className={styles.className}>{regClasses[1].name}</Link></>
                                        :
                                        regClasses.length >= 3 ?
                                            <>
                                                <Link to={`/classes/${regClasses[0].id}`} className={styles.className}>{regClasses[0].name}</Link>, <Link to={`/classes/${regClasses[1].id}`} className={styles.className}>{regClasses[1].name}</Link> and <Tooltip
                                                    size="big"
                                                    arrow="true"
                                                    html={(
                                                        <>
                                                            {regClasses.slice(2, regClasses.length).map(c => {
                                                                return <p key={`classTip${c.id}${s.id}`}>{c.name}</p>
                                                            })}
                                                        </>
                                                    )}
                                                >
                                                    <span className={styles.classLink} onClick={() => setStudent(s)}>{regClasses.length - 2} other{regClasses.length > 3 && 's'}</span>
                                                </Tooltip>
                                            </>
                                            :
                                            <span>0 Registration Classes</span>
                                }
                            </>
                        }
                    </div>
                </div>
                <p>{s.upn && s.upn}</p>
            </li>
        )
    }


    useEffect(() => {
        if (currentYear) {
            if (currentYear === 'UPN') {
                getSchoolStudentList({
                    variables: {
                        schoolId: user && user.school.id,
                        withoutUpn: true,
                    }
                })
            } else if (currentYear === 'INACTIVE') {
                getArchivedList({
                    variables: {
                        schoolId: user && user.school.id,
                    }
                })
            } else if (currentYear === 'U' || currentYear === 'NOCLASSES') {
                getUnassignedStudentList({
                    variables: {
                        schoolId: user && user.school.id,
                    }
                })
            } else if (currentYear !== 'DUPE') {
                getSchoolStudentList({
                    variables: {
                        schoolId: user && user.school.id,
                        yearGroup: currentYear.toString(),
                    }
                })
            }
        }
        setSelectAll(false)
        setSelectedStudents([])
        setStToMerge([])
    }, [currentYear])


    useEffect(() => {
        if (searchTerm && searchTerm.length > 1) {
            intervalRef.current = setTimeout(() => {
                searchStudents({ variables: { schoolId: user && user.school.id, nameOrUpn: searchTerm } })
            }, 600);
        } else {
            setResults([]);
            setNoStudents(false)
            clearTimeout(intervalRef.current);
        }
        return () => clearTimeout(intervalRef.current);
    }, [searchTerm]);



    useEffect(() => {
        if (selectAll) {
            const cIds = currentYear === 'U' ? unassignedData?.school.unassignedStudents.map(c => c.id) : currentYear === 'INACTIVE' ? archivedData?.school.inactiveStudents.map(c => c.id) : currentYear === 'NOCLASSES' ? unassignedData?.school.studentsWithoutYearGroup.map(c => c.id) : filteredData?.school.filteredStudents.map(c => c.id);
            setSelectedStudents(cIds)
            const stForMerge = currentYear === 'U' ? unassignedData?.school.unassignedStudents.map(c => c) : currentYear === 'NOCLASSES' ? unassignedData?.school.studentsWithoutYearGroup.map(c => c) : filteredData?.school?.filteredStudents.map(c => c);
            stForMerge?.length === 2 && setStToMerge(stForMerge)
        } else {
            setSelectedStudents([])
            setStToMerge([])
        }
    }, [selectAll])

    const compare = (a, b) => {
        if (a.lastName < b.lastName) {
            return -1;
        }
        if (a.lastName > b.lastName) {
            return 1;
        }
        return 0;
    }

    const compareDates = (a, b) => {
        if (a.schoolMemberships[0].removedAt < b.schoolMemberships[0].removedAt) {
            return -1;
        }
        if (a.schoolMemberships[0].removedAt > b.schoolMemberships[0].removedAt) {
            return 1;
        }
        return 0;
    }

    // Delete multiple unassigned students from school
    const [showDeleteModalMultiple, setshowDeleteModalMultiple] = useState({
        selectedStudentsIds: null,
    });
    const [enableDelete, setEnableDelete] = useState('');
    

    const [removeStudentsFromSchool] = useMutation(REMOVE_STUDENTS_FROM_SCHOOL, {
        update: (_, { data }) => {
            if (data.removeStudentsFromSchool.errors && data.removeStudentsFromSchool.errors.length) {
                setEnableDelete('');
                setSelectedStudents([]);
                return;
            }
            if (data.removeStudentsFromSchool.students) {
                setshowDeleteModalMultiple({ ...showDeleteModalMultiple, selectedStudentsIds: null });
                setEnableDelete('');
                setSelectedStudents([]);
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: () => getUnassignedStudentList({
            variables: {
                schoolId: user && user.school.id,
            }
        })

    });

    const onDeleteStudents = ({selectedStudentsIds}) => {
        removeStudentsFromSchool({
            variables: {
                studentIds: selectedStudentsIds,
                schoolId: user && user.school.id
            }
        })
    }
  
    if (loading) {
        return <p>Loading</p>
    }

    if (error) {
        return <p>Something went wrong</p>
    }

    if (data) {
        return (
            <> {user && user.school.newYearStep < 99 && source !== 'NewYear' && source !== 'NewClasses' ?
                <>
                    <PageHeader title={`Manage Students`} icon={Icon.Users} />
                    <NewSchoolYearWarning />
                </>
                :
                !currentStudent &&
                <>
                    {source !== 'NewYear' && source !== 'NewClasses' &&
                        <PageHeader title={`Manage Students`} icon={Icon.Users} />
                    }
                    <div className={styles.container}>
                        <div>
                            <div className={cx(styles.tabContainer, source === 'NewYear' && styles.newYearTabs)}>
                                <ul className={styles.tabList}>
                                    {data.school.yearGroupCounters.map(y => {
                                        if (y.yearGroup) {
                                            return <li className={cx(styles.yearTab, y.yearGroup === currentYear ? styles.yearTabActive : null)} key={`y${y.id}`} onClick={() => setCurrentYear(y.yearGroup)}>
                                                <span className={styles.yearTabLbl}>{getYearGroupName(y.yearGroup)}</span>
                                                <span className={styles.yearTabCounter}>{y.studentCounter} <Icon.User size="14" className={styles.yearTabIcon} /></span>
                                            </li>
                                        } else {
                                            return null
                                        }
                                    })}
                                </ul>
                                {source !== 'NewClasses' &&
                                    <ul className={styles.tabList}>
                                        {source === 'NewYear' ?
                                            data && data.school.yearGroupCounters.filter(x => !x.yearGroup)[0] &&
                                            <li className={cx(styles.yearTab, currentYear === 'NOCLASSES' && styles.yearTabActive)} onClick={() => setCurrentYear('NOCLASSES')}>
                                                <span className={styles.yearTabLbl}>No Year Group</span>
                                                <span className={styles.yearTabCounter}>{data && data.school.yearGroupCounters.filter(x => !x.yearGroup)[0].studentCounter} <Icon.User size="14" className={styles.yearTabIcon} /></span>
                                            </li>
                                            :
                                            <li className={cx(styles.yearTab, currentYear === 'U' && styles.yearTabActive)} onClick={() => setCurrentYear('U')}>
                                                <span className={styles.yearTabLbl}>Unassigned</span>
                                                <span className={styles.yearTabCounter}>{unassignedData?.school?.unassignedStudents ? unassignedData?.school?.unassignedStudents.length : data.school.unassignedStudentsCounter} <Icon.User size="14" className={styles.yearTabIcon} /></span>
                                            </li>
                                        }
                                        <li className={cx(styles.yearTab, currentYear === 'UPN' && styles.yearTabActive)} onClick={() => setCurrentYear('UPN')}>
                                            <span className={styles.yearTabLbl}>No UPN</span>
                                            <span className={styles.yearTabCounter}>{data.school.studentsWithoutUpnCounter} <Icon.User size="14" className={styles.yearTabIcon} /></span>
                                        </li>
                                        {data.school.duplicatedStudentsData.length > 0 && user.isSchoolAdmin &&
                                            <li className={cx(styles.yearTab, currentYear === 'DUPE' && styles.yearTabActive)} onClick={() => setCurrentYear('DUPE')}>
                                                <span className={styles.yearTabLbl}>Duplicates</span>
                                                <span className={styles.yearTabCounter}>{data.school.duplicatedStudentsData.length} <Icon.User size="14" className={styles.yearTabIcon} /></span>
                                            </li>
                                        }
                                        {user.isSchoolAdmin && source !== 'NewYear' && source !== 'NewClasses' &&
                                            <li className={cx(styles.yearTab, currentYear === 'INACTIVE' && styles.yearTabActive)} onClick={() => setCurrentYear('INACTIVE')}>
                                                <span className={styles.yearTabLbl}>Archived</span>
                                            </li>
                                        }
                                    </ul>
                                }
                            </div>
                        </div>
                        <div className={styles.studentList}>
                            {source !== 'NewYear' && source !== 'NewClasses' &&
                                <div className={styles.searchAndAdd}>
                                    <div className={styles.search}>
                                        <div className={styles.inputHolder}>
                                            <input
                                                name='search'
                                                className={cx('basic-form__text-box u-m-base-0', styles.searchBox)}
                                                type='text'
                                                autoComplete='off'
                                                placeholder='Search by name UPN'
                                                value={searchTerm}
                                                style={{ marginRight: '10px' }}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                            />
                                            <Icon.Search size="20" className={styles.searchIcon} />
                                        </div>
                                        {searchLoading && <img width="30" src={loader} alt='loading' />}
                                        {searchError && <p>{error.message}</p>}
                                        {results.length > 0 && (
                                            <div className={styles.resultsWrapper}>
                                                <ul className={styles.resultsWrapperList}>
                                                    {results.map(result => (
                                                        <li key={result.id}>
                                                            <span className={styles.searchLink} onClick={() => { setStudent(result); setSearchTerm("") }}>
                                                                <img src={result.boomerAvatarUrl ? `${result.boomerAvatarUrl}?v=${result.updatedAt.replace(/\s/g, '')}` : boomer} className={styles.studentAva} />
                                                                <span className={styles.studentName}>{result.firstName} {result.lastName}</span>
                                                                <span className={styles.className}>{result.classes.filter(x => x.typeId === 1).length > 0 && result.classes.filter(x => x.typeId === 1)[0].name}</span>
                                                            </span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                        {noStudents &&
                                            <div className={styles.resultsWrapper}>
                                                <ul className={styles.resultsWrapperList}>
                                                    <li className={styles.searchLinkNotFound}>
                                                        <Icon.AlertCircle size="25" style={{ marginRight: '5px' }} />
                                                        <span>No Students Found</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                    {!user?.school?.wondeAccessApprovedAt &&
                                        <Button onClick={() => setShowAdd(true)} primary>Add Student</Button>
                                    }
                                </div>
                            }
                            {currentYear && currentYear !== 'DUPE' &&
                                <div className={styles.moveActions}>
                                    <div>
                                        <input
                                            name="class"
                                            type="checkbox"
                                            value={selectAll}
                                            checked={selectAll}
                                            onChange={() => setSelectAll(!selectAll)}
                                            id="SelectAll"
                                            style={{
                                                marginRight: '10px'
                                            }}
                                        />
                                        <label htmlFor="SelectAll" style={{ marginRight: '50px' }}>All/None</label>
                                    </div>
                                    {currentYear === 'INACTIVE' ?
                                        <Button disabled={restoreLoading || selectedStudents.length === 0} className="u-m-right-2" onClick={() => restoreStudents({ variables: { studentIds: selectedStudents } })}>Reinstate</Button>
                                        :
                                        <>
                                            <button className="u-m-right-2 btn-outline-muted" disabled={selectedStudents.length === 0} onClick={() => setShowMoveClass(true)}>{source === 'NewClasses' ? 'Select/Create Class' : 'Add To Class'}</button>
                                            {source !== 'NewClasses' && <button className="u-m-right-2 btn-outline-muted" disabled={selectedStudents.length === 0} onClick={() => setShowMoveYear(true)}>Move Year</button>}
                                            <button className="u-m-right-2 btn-outline-muted" disabled={selectedStudents.length === 0} onClick={() => setShowArchive(true)}>Archive</button>
                                            {currentYear === 'U' && <button className="u-m-right-2 btn-outline-muted" disabled={selectedStudents.length === 0} onClick={() => {setshowDeleteModalMultiple({...showDeleteModalMultiple, selectedStudentsIds: selectedStudents })}}>Delete</button> }

                                            <span className="u-m-left-auto">
                                                <button className="u-m-right-1 btn-outline-muted" disabled={selectedStudents.length === 0 || stToMerge.length !== 2} onClick={() => setShowMerge(true)}>Merge</button>
                                                <Tooltip
                                                    arrow="true"
                                                    size='big'
                                                    html={(
                                                        <div style={{ maxWidth: '200px' }}>Merge 2 students accounts if they are a duplicate of the same student</div>
                                                    )}>
                                                    <Icon.HelpCircle size={18} />
                                                </Tooltip>
                                            </span>
                                        </>
                                    }
                                </div>
                            }
                            {currentYear === 'U' ?
                                <div>
                                    {unassignedLoading && <p>Loading Students</p>}
                                    {(unassignedData && !unassignedLoading) &&
                                        <ul className={styles.studentItemsWrapper}>
                                            {unassignedData.school.unassignedStudents.sort(compare).map(s => {
                                                return (
                                                    studentItem(s)
                                                )
                                            })}
                                        </ul>
                                    }
                                </div>
                                :
                                currentYear === 'NOCLASSES' ?
                                    <div>
                                        {unassignedLoading && <p>Loading Students</p>}
                                        {(unassignedData && !unassignedLoading) &&
                                            <ul className={styles.studentItemsWrapper}>
                                                {unassignedData.school.studentsWithoutYearGroup.sort(compare).map(s => {
                                                    return (
                                                        studentItem(s)
                                                    )
                                                })}
                                            </ul>
                                        }
                                    </div>
                                    :
                                    currentYear === 'DUPE' ?
                                        <div>
                                            {
                                                data && data.school.duplicatedStudentsData &&
                                                <ul className={styles.studentItemsWrapper}>
                                                    {data.school.duplicatedStudentsData.map(s => {
                                                        return <Duplicate key={`dupeSet${s.id}`} s={s} setStudent={setStudent} compare={compare} setProcessing={setProcessing} processing={processing} schoolId={user && user.school.id} />
                                                    })}
                                                </ul>

                                            }
                                        </div>
                                        :
                                        currentYear === 'INACTIVE' ?
                                            <div>
                                                {
                                                    archivedData && archivedData.school.inactiveStudents &&
                                                    <ul className={styles.studentItemsWrapper}>
                                                        {archivedData.school.inactiveStudents.sort(compareDates).map(s => {
                                                            return studentItem(s)
                                                        })}
                                                    </ul>

                                                }
                                            </div>
                                            :
                                            <div>
                                                {filteredLoading && <p>Loading Students</p>}
                                                {(filteredData && !filteredLoading) &&
                                                    <ul className={styles.studentItemsWrapper}>
                                                        {filteredData.school.filteredStudents.sort(compare).map(s => {
                                                            return (
                                                                studentItem(s)
                                                            )
                                                        })}
                                                    </ul>
                                                }
                                            </div>
                            }
                        </div>
                    </div>
                </>
            }
                {currentStudent &&
                    <Student currentUser={user} student={currentStudent} close={setStudent} currentYear={currentYear} />
                }
                {showAdd &&
                    <Modal closeModal={() => setShowAdd(false)}>
                        <AddStudent schoolId={user.school.id} currentYear={currentYear} />
                    </Modal>
                }
                {showMoveClass &&
                    <MoveClasses school={user.school} schoolId={user.school.id} close={() => setShowMoveClass(false)} students={selectedStudents} currentYear={currentYear} clearStudents={() => { setSelectedStudents([]); setSelectAll(false) }} />
                }
                {showArchive && (
                    <Modal closeModal={() => setShowArchive(false)}>
                        <ArchiveStudents close={() => setShowArchive(false)} schoolId={user.school.id} students={selectedStudents} currentYear={currentYear} clearStudents={() => { setSelectedStudents([]); setSelectAll(false) }} />
                    </Modal>
                )}
                {showMerge && (
                    <Modal closeModal={() => setShowMerge(false)}>
                        <MergeStudents currentYear={currentYear} stToMerge={stToMerge} schoolId={user.school.id} close={() => setShowMerge(false)} clearStudents={() => { setSelectedStudents([]); setSelectAll(false); setStToMerge([]) }} />
                    </Modal>
                )}
                {showMoveYear &&
                    <MoveYear schoolId={user.school.id} close={() => setShowMoveYear(false)} students={selectedStudents} currentYear={currentYear} clearStudents={() => { setSelectedStudents([]); setSelectAll(false) }} />
                }
                {processing &&
                    <div className={styles.processing}>
                        <p className="u-m-base-2">Merging students&hellip;this may take a moment.</p>
                        <img src={loader} />
                    </div>
                }

                {
                    showDeleteModalMultiple.selectedStudentsIds && (
                        <Modal closeModal={() => {
                            setshowDeleteModalMultiple({ ...showDeleteModalMultiple, selectedStudentsIds: null })
                            setEnableDelete('')
                            }}
                        >
                            <div className='u-text-center'>
                                <Fragment>
                                    <h3>{t('common:are_you_sure')}</h3>
                                    <p className='u-m-base-2'>{t('this_will_remove_pupils_and_delete_all_data')}</p>
                                    <p className='u-m-base-1'>{t('please_type_delete_to_confirm')}:</p>
                                    <input
                                        className='basic-form__text-box u-m-base-2'
                                        type="text"
                                        value={enableDelete}
                                        onChange={(e) => setEnableDelete(e.target.value)}
                                    />
                                    <p className='u-m-base-1'><Button onClick={() => onDeleteStudents(showDeleteModalMultiple)} disabled={enableDelete !== 'DELETE'}>{t('i_understand_delete')}</Button></p>
                                    <Button outline onClick={() => {
                                        setshowDeleteModalMultiple({ ...setshowDeleteModalMultiple, selectedStudentsIds: null })
                                        setEnableDelete('')
                                        }}
                                        >
                                        {t('common:button.cancel')}</Button>
                                </Fragment>
                            </div>
                        </Modal>
                    )
                }
            </>             
        )
    }

    return null
}

export default Students
import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../../services/auth/AuthProvider";
import { useMutation, useApolloClient, useLazyQuery } from "@apollo/react-hooks";
import { UPDATE_SCHOOL_SETUP_STEP } from "../../../../services/school/graphql";
import { IMPORT_STUDENTS_FROM_EXCEL_TO_SCHOOL, IMPORT_TICKET_QUERY } from '../../services/graphql';
import { GET_S3_UPLOAD_URL_QUERY } from '../../../../services/graphql';

import { FormErrors } from "@axeedge/go-teacher-components";
import axios from 'axios';
import ManageClassesImportData from "./ManageClassesImportData";
import UploadActionCable from "./UploadActionCable";
import { AlertTriangle, Upload, AlertCircle } from "react-feather";
import ImageFadeIn from "react-image-fade-in";
import csvImg from '../../../../images/csv-preview-classes.jpg';

import styles from './UploadClassesPupils.module.scss';
import cx from 'classnames';
import { SCHOOL_SETUP_STEPS } from "../../../../services/constants";

const IMPORT_STATUS = {
    ongoing: 0,
    preprocessed: 1,
    done: 2,
    failed: 9,
    ongoingFinalize: 99
}


const UploadClassesPupils = ({ school, isOnboarding = false, onUploadDone, exit }) => {
    const { currentUser, setCurrentUser } = useContext(AuthContext);
    const client = useApolloClient();

    const [formErrors, setFormErrors] = useState([]);
    const [uploadError, setUploadError] = useState(null);
    const [finalizeErrors, setFinalizeErrors] = useState([]);

    const [manageUploadData, setManageUploadData] = useState(null);
    const [ticketId, setTickedId] = useState(null);
    const [importDone, setImportDone] = useState(false);
    const [progress, setProgress] = useState(null);
    const [receivedData, setReceivedData] = useState(null);

    const [updateSchoolSetupStep, { loading: updatingStep }] = useMutation(UPDATE_SCHOOL_SETUP_STEP, {
        update: (_, { data }) => {
            if ((data && data.updateSchoolSetupStep.errors && data.updateSchoolSetupStep.errors.length > 0)) {
                setFormErrors(data.updateSchoolSetupStep.errors);
                return;
            }
            if (data?.updateSchoolSetupStep?.school?.id) {
                const cu = { ...currentUser, school: data.updateSchoolSetupStep.school }
                setCurrentUser(cu);
            }
        }
    });


    const [onSave, { loading }] = useMutation(IMPORT_STUDENTS_FROM_EXCEL_TO_SCHOOL, {
        update: (_, { data }) => {
            if (data.importStudentsFromXlsxToSchool.errors && data.importStudentsFromXlsxToSchool.errors.length !== 0) {
                setUploadError(data.importStudentsFromXlsxToSchool.errors[0]);
                return;
            }
            if (data.importStudentsFromXlsxToSchool.importFile.errors && data.importStudentsFromXlsxToSchool.importFile.errors.length !== 0) {
                setUploadError(data.importStudentsFromXlsxToSchool.importFile.errors[0]);
                return;
            }
            if (data?.importStudentsFromXlsxToSchool?.importFile?.id) {
                setTickedId(data.importStudentsFromXlsxToSchool.importFile.id);
            }
        }
    });

    const [getImportData] = useLazyQuery(IMPORT_TICKET_QUERY, {
        fetchPolicy: 'netork-only',
        onCompleted: (data) => {
            setManageUploadData(data);
            setUploadError(null);
            setProgress(null);
        }
    });

    useEffect(() => {
        if (receivedData) {
            receivedData.progress && setProgress(receivedData.progress)
            if (receivedData.status === IMPORT_STATUS.preprocessed) {
                getImportData({
                    variables: {
                        importTicketId: ticketId,
                        limit: 5
                    }
                });
            } else if (receivedData.status === IMPORT_STATUS.failed) {
                setUploadError(receivedData.import_errors);
            } else if (receivedData.status === IMPORT_STATUS.done) {
                if (receivedData.imported === receivedData.total) {
                    setImportDone(true);
                } else {
                    setFinalizeErrors([`Imported ${receivedData.imported} out of ${receivedData.total}.`]);
                }
            }
        }
    }, [receivedData, ticketId]);

    const updateStep = () => {
        updateSchoolSetupStep({
            variables: {
                schoolId: currentUser?.school.id,
                step: SCHOOL_SETUP_STEPS.classesStepDone
            }
        });
    }

    useEffect(() => {
        if (importDone) {
            isOnboarding ? updateStep() : onUploadDone();
        }
    }, [importDone]);


    const resetImport = () => {
        setManageUploadData(null);
        setTickedId(null);
        setReceivedData(null);
        setProgress(null);
        setUploadError(null);
    }
    const setManageDone = () => {
        getImportData({
            variables: {
                importTicketId: ticketId,
                limit: 0
            }
        });
    }

    const onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const fileName = `school-classes-list-${school.id}-${new Date().getTime()}.xlsx`;
            const file = e.target.files[0];
            client.query({
                query: GET_S3_UPLOAD_URL_QUERY,
                variables: {
                    name: fileName
                }
            }).then(r => {
                const options = { headers: { 'Content-Type': '', 'x-amz-acl': 'public-read' } }
                axios.put(r.data.s3UploadUrl, file, options).then(s3r => {
                    onSave({
                        variables: {
                            fileName,
                            schoolId: school.id,
                        }
                    });
                })
            })
        }
    }

    return (
        <>
            {ticketId && <UploadActionCable ticketId={ticketId} setReceivedData={setReceivedData} />}

            {manageUploadData ? (
                <ManageClassesImportData
                    manageUploadData={manageUploadData}
                    updatingStep={updatingStep}
                    close={() => resetImport()}
                    school={school}
                    isOnboarding={isOnboarding}
                    finalizeErrors={finalizeErrors}
                    progress={progress}
                    setManageDone={setManageDone}
                    onUpdateSetupStep={() => updateStep()}
                />
            ) : (
                <div className="card-mobile">
                    <div className={cx(styles.uploadStartHeader, 'u-m-base-2')}>
                        <h1>
                            {isOnboarding ? 'Upload your students' : <><AlertCircle /> Only for creating <span className="u-underline">new</span> student accounts</>}</h1>
                        <p>Create the registration classes and pupils that will be using the app. Upload an xlsx file that includes the pupils and their registration class.</p>
                    </div>
                    <div className={styles.uploadStart}>
                        <div>
                            {progress && !uploadError ?
                                <div className={cx(styles.panel, 'u-m-base-3')}>
                                    <h2>Importing file, please wait...</h2>
                                    <p className='heavy'>Progress: {progress}%</p>
                                </div>
                                :
                                <>
                                    {uploadError && (
                                        <div className='basic-form__errors'>
                                            <AlertTriangle className='basic-form__errors__icon' />
                                            <p>{uploadError}</p>
                                        </div>
                                    )}
                                    <div className={styles.panel}>
                                        <ImageFadeIn className='u-m-base-2' src={csvImg} alt='' />
                                        <h3>How do pupil accounts work?</h3>
                                        <p>Pupils log in using a school code and unique PIN number that we generate.</p>
                                        <p className="u-m-base-2">Teachers are able to download login sheets to hand out for their pupils.</p>
                                        <div className={styles.uploadStartInfo}>
                                            <div>
                                                <p>Please use the following values for the year groups. Nursery and Reception use 'N' and 'R' respectively and other year groups use the numerical value only.</p>
                                            </div>
                                            <div className={styles.uploadStartYearGroup}>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Year Group Name</th>
                                                            <th>Value</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Nursery</td>
                                                            <td>N</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Reception</td>
                                                            <td>R</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Year 1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Year 2</td>
                                                            <td>2</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <a href="https://boomapps.s3.eu-west-2.amazonaws.com/sample-pupils-class-upload.xlsx" target='blank' className="link-underline u-text-primary">Download template file</a>
                                    </div>
                                    {!isOnboarding &&
                                        <div className={styles.warningPanel}>
                                            <div><AlertCircle color="#FFF" /></div>
                                            <div>
                                                <h3>Please Note</h3>
                                                <p className="u-m-base-2">Upload an xlsx file</p>
                                                <p className="u-m-base-2">UPN numbers are optional</p>
                                                <p>Do not add existing students into the xlsx file.</p>
                                            </div>
                                        </div>
                                    }
                                    <div className="u-m-base-2">
                                        <div className={styles.buttonContainer}>
                                            <input disabled={loading} className={styles.inputFile} id='list' name='list' type='file' accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' onChange={onSelectFile} />
                                            <label htmlFor='list'><Upload size='20' className={styles.buttonIcon} />Upload Excel File</label>
                                        </div>

                                        {isOnboarding ?
                                            <button disabled={updatingStep} onClick={() => updateSchoolSetupStep({
                                                variables: {
                                                    schoolId: currentUser?.school.id,
                                                    step: SCHOOL_SETUP_STEPS.classesStepDone
                                                }
                                            })} className="btn-reset link-underline u-text-primary u-m-left-2">{updatingStep ? 'Saving...' : 'I\'ll do this later'}</button>
                                            : <button className="btn-reset link-underline u-text-primary u-m-left-2" onClick={exit}>Cancel</button>}
                                        {formErrors && <FormErrors errors={formErrors} />}
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default UploadClassesPupils;
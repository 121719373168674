import React, { Fragment, useState, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { AuthContext } from '../../../../../services/auth/AuthProvider';
import { YEAR_GROUPS } from '@axeedge/go-shared-utils';

import { Button, Modal, FormErrors } from '@axeedge/go-teacher-components';
import { getYearGroupLabel } from '../../../../../services/utils';
import ListItem from '../../../../../components/ListItem';
import EditStudent from './EditStudent';
import MoveToClass from './MoveToClass';
import LoginCode from './LoginCode';
import { REMOVE_STUDENT_FROM_SCHOOL, REMOVE_STUDENT_FROM_CLASS, REMOVE_STUDENTS_FROM_SCHOOL } from '../services/graphql';
import { GET_CLASS_QUERY, GET_UNASSIGNED_STUDENTS_QUERY } from '../../../services/graphql';
import { useTranslation } from 'react-i18next';
import styles from '../Students.module.scss';

const StudentsList = ({ classId, students, schoolId, multipleSelection }) => {
    const { t } = useTranslation(['classes', 'common']);
    const auth = useContext(AuthContext);
    const { currentUser: teacher } = auth;

    const setDeleteType = () => {
        if (!teacher.isSchoolAdmin) {
            return 'class'
        }
        if (!classId) {
            return 'school'
        }
        return null
    };

    const showDeleteLink = !teacher.school?.wondeAccessApprovedAt && (teacher.isSchoolAdmin || classId);

    const showYearGroupWarning = student => {
        return student.yearGroup && !YEAR_GROUPS.find(yg => yg.val === student.yearGroup) && !teacher.school?.wondeAccessApprovedAt
    };

    const [showEditStudentModal, setShowEditStudentModal] = useState(false);
    const [showStudentModal, setShowStudentModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState({
        student: null,
        deleteType: setDeleteType(),
    });
    const [showMove, setShowMove] = useState(false);
    const [showMoveMultiple, setShowMoveMultiple] = useState(false);

    const [fromEditModal, setFromEditModal] = useState(false);
    const [enableDelete, setEnableDelete] = useState('');

    const [formErrors, setFormErrors] = useState([]);

    const studentQueries = () => {
        if (classId) {
            return [{
                query: GET_CLASS_QUERY,
                variables: {
                    id: classId
                }
            }]
        } else {
            return [{
                query: GET_UNASSIGNED_STUDENTS_QUERY,
                variables: {
                    schoolId: schoolId
                }
            }]
        }
    }

    const [removeStudentFromClass] = useMutation(REMOVE_STUDENT_FROM_CLASS, {
        update: (_, { data }) => {
            if (data.removeStudentFromClass.errors && data.removeStudentFromClass.errors.length) {
                setFormErrors(data.removeStudentFromClass.errors);
                return;
            }
            if (data.removeStudentFromClass.deleted) {
                setShowDeleteModal({ ...showDeleteModal, student: null, deleteType: null })
            }
        },
        refetchQueries: [{ query: GET_CLASS_QUERY, variables: { id: classId } }],
        awaitRefetchQueries: true
    });

    const [removeStudentFromSchool] = useMutation(REMOVE_STUDENT_FROM_SCHOOL, {
        update: (_, { data }) => {
            if (data.removeStudentFromSchool.errors && data.removeStudentFromSchool.errors.length) {
                setFormErrors(data.removeStudentFromSchool.errors);
                return;
            }
            if (data.removeStudentFromSchool.deleted) {
                setShowDeleteModal({ ...showDeleteModal, student: null, deleteType: null })
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: () => studentQueries()

    });

    const onDeleteStudent = data => {
        if (data.deleteType === 'class') {
            removeStudentFromClass({
                variables: {
                    studentId: data.student.id,
                    studentsClassId: classId
                }
            })
        } else if (data.deleteType === 'school') {
            removeStudentFromSchool({
                variables: {
                    studentId: data.student.id,
                    schoolId: schoolId
                }
            })
        }
    }

    // Multiple selection section

    const [selectedStudents, setSelectedStudents] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [showDeleteModalMultiple, setshowDeleteModalMultiple] = useState({
        selectedStudentsIds: null,
    });
        
    const onSelectStudent = (e) => {
        const selectedStudentId = e.target.value;
        if (selectedStudents.indexOf(selectedStudentId) !== -1) {
            setSelectedStudents(selectedStudents.filter(s => s !== selectedStudentId));
        } else {
            setSelectedStudents([...selectedStudents, selectedStudentId]);
        }
    }

    useEffect(() => {
        if (selectAll) {
            setSelectedStudents(students.map((student) => student.id))
        } else {
            setSelectedStudents([])
        }
    }, [selectAll])


    const [removeStudentsFromSchool] = useMutation(REMOVE_STUDENTS_FROM_SCHOOL, {
        update: (_, { data }) => {
            if (data.removeStudentsFromSchool.errors && data.removeStudentsFromSchool.errors.length) {
                setFormErrors(data.removeStudentsFromSchool.errors);
                setEnableDelete('')
                setSelectedStudents([]);
                return;
            }
            if (data.removeStudentsFromSchool.students) {
                setshowDeleteModalMultiple({ ...showDeleteModalMultiple, selectedStudentsIds: null });
                setEnableDelete('');
                setSelectedStudents([]);
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: () => studentQueries()

    });

    const onDeleteStudents = ({selectedStudentsIds}) => {
        removeStudentsFromSchool({
            variables: {
                studentIds: selectedStudentsIds,
                schoolId
            }
        })
    }

    return (
        <div className='hidden-print'>
            {
                students.length === 0 ?
                    <p className='u-m-base-2'>{t('add_first_pupil_for_class')}:</p> : 
                    <div>
                        {
                            multipleSelection && 
                            <div className={styles.unassignedStudentsActions }>
                                <input
                                    name="class"
                                    type="checkbox"
                                    value={selectAll}
                                    checked={selectAll}
                                    onChange={() => setSelectAll(!selectAll)}
                                    id="SelectAll"
                                    style={{marginRight: '10px'}}
                                />
                                <label htmlFor="SelectAll" style={{ marginRight: '50px' }}>All/None</label>

                                <button className="u-m-right-2 btn-outline-muted" disabled={!selectedStudents.length} onClick={() => {setshowDeleteModalMultiple({...showDeleteModalMultiple, selectedStudentsIds: selectedStudents })}}>Delete</button>
                                { !teacher.school?.canAccessWonde && <button className="u-m-right-2 btn-outline-muted" disabled={!selectedStudents.length} onClick={() => setShowMoveMultiple(true)}>Add To Class</button> } 
                            </div>
                        } 
                        <div className={styles.scrollableContainer}>
                            {
                                students.map(student =>
                                    <div className={styles.studentListItemContainer} key={`student-${student.id}`}>
                                        { 
                                            multipleSelection &&
                                            <input value={student.id} type="checkbox" checked={selectedStudents.indexOf(student.id) > -1} onChange={(e) => onSelectStudent(e)} />
                                        }

                                        <div className={styles.studentListItem}>
                                        <ListItem
                                            avatarJson={student.avatarJson}
                                            boomerAvatarUrl={student.boomerAvatarUrl}
                                            updatedAt={student.updatedAt}
                                            mainText={student.name}
                                            onClick={() => setShowStudentModal(student)}
                                            {...(!multipleSelection && showDeleteLink && { onDelete: () => setShowDeleteModal({ ...showDeleteModal, student: student }) })}
                                            {...(!teacher.school?.wondeAccessApprovedAt && { onSettings: () => setShowEditStudentModal(student) })}
                                            {...(showYearGroupWarning(student) && { onWarning: () => setShowEditStudentModal(student) })}
                                            {...(!multipleSelection && !teacher.school?.wondeAccessApprovedAt && { onMove: () => setShowMove(student) })}
                                            short
                                            withCard
                                            isStudent={true}
                                            showTip={!teacher.school?.wondeAccessApprovedAt}
                                            yearGroup={getYearGroupLabel(student.yearGroup)}
                                        />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
            }
            {
                showEditStudentModal && (
                    <Modal closeModal={() => {
                        setShowEditStudentModal(false);
                        setFromEditModal(false);
                    }}>
                        <EditStudent
                            setShowStudentModal={setShowStudentModal}
                            schoolId={schoolId}
                            closeModal={() => {
                                setShowEditStudentModal(false);
                                setFromEditModal(false);
                            }}
                            student={showEditStudentModal}
                            setFromEditModal={setFromEditModal}
                        />
                    </Modal>
                )
            }
            {
                showStudentModal && (
                    <Modal closeModal={() => setShowStudentModal(false)}>
                        <LoginCode
                            student={showStudentModal}
                            closeModal={() => setShowStudentModal(false)}
                            setShowEditStudentModal={setShowEditStudentModal}
                            fromEditModal={fromEditModal}
                        />
                    </Modal>
                )
            }

            {
                showDeleteModal.student && (
                    <Modal closeModal={() => setShowDeleteModal({ ...showDeleteModal, student: null, deleteType: setDeleteType() })}>
                        <div className='u-text-center'>
                            {
                                !showDeleteModal.deleteType ? (
                                    <Fragment>
                                        <h3 className='u-m-base-2'>{t('delete_student', { studentName: showDeleteModal.student.name })} </h3>
                                        <p className='u-m-base-2'>{t('please_choose_delete_option')}:</p>
                                        <Button className='btn-small u-m-right-1' outline onClick={() => setShowDeleteModal({ ...showDeleteModal, deleteType: 'class' })}>{t('remove_from_class')}</Button>
                                        <Button className='btn-small' outline onClick={() => setShowDeleteModal({ ...showDeleteModal, deleteType: 'school' })}>{t('delete_from_school')}</Button>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        {
                                            showDeleteModal.deleteType === 'school' ? (
                                                <Fragment>
                                                    <h3 className='u-m-base-3'>{t('delete_student_from_this_school', { studentName: showDeleteModal.student.name })}</h3>
                                                    <h3>{t('common:are_you_sure')}</h3>
                                                    <p className='u-m-base-2'>{t('this_will_remove_pupil_and_delete_all_data')}</p>
                                                    <p className='u-m-base-1'>{t('please_type_delete_to_confirm')}:</p>
                                                    <input
                                                        className='basic-form__text-box u-m-base-2'
                                                        type="text"
                                                        value={enableDelete}
                                                        onChange={(e) => setEnableDelete(e.target.value)}
                                                    />
                                                    <p className='u-m-base-1'><Button onClick={() => onDeleteStudent(showDeleteModal)} disabled={enableDelete !== 'DELETE'}>{t('i_understand_delete')}</Button></p>
                                                    <Button outline onClick={() => setShowDeleteModal({ ...showDeleteModal, student: null, deleteType: setDeleteType() })}>{t('common:button.cancel')}</Button>
                                                </Fragment>
                                            ) : (
                                                <Fragment>
                                                    <h3>{t('remove_student', { studentName: showDeleteModal.student.name })}  </h3>
                                                    <p className='u-m-base-2'>{t('remove_student_only_from_this_class', { studentName: showDeleteModal.student.name })}</p>
                                                    <Button onClick={() => onDeleteStudent(showDeleteModal)} className='u-m-right-2'>{t('common:button.remove')}</Button>
                                                    <Button outline onClick={() => setShowDeleteModal({ ...showDeleteModal, student: null, deleteType: setDeleteType() })}>{t('common:button.cancel')}</Button>
                                                </Fragment>
                                            )
                                        }


                                    </Fragment>
                                )
                            }
                        </div>
                    </Modal>
                )
            }

            {
                showDeleteModalMultiple.selectedStudentsIds && (
                    <Modal closeModal={() => {
                        setshowDeleteModalMultiple({ ...showDeleteModalMultiple, selectedStudentsIds: null });
                        setEnableDelete('')}}
                    >
                        <div className='u-text-center'>
                            <Fragment>
                                <h3>{t('common:are_you_sure')}</h3>
                                <p className='u-m-base-2'>{t('this_will_remove_pupils_and_delete_all_data')}</p>
                                <p className='u-m-base-1'>{t('please_type_delete_to_confirm')}:</p>
                                <input
                                    className='basic-form__text-box u-m-base-2'
                                    type="text"
                                    value={enableDelete}
                                    onChange={(e) => setEnableDelete(e.target.value)}
                                />
                                <p className='u-m-base-1'><Button onClick={() => onDeleteStudents(showDeleteModalMultiple)} disabled={enableDelete !== 'DELETE'}>{t('i_understand_delete')}</Button></p>
                                <Button outline onClick={() => {
                                    setshowDeleteModalMultiple({ ...setshowDeleteModalMultiple, selectedStudentsIds: null }) ; setEnableDelete('')
                                }}
                                >{t('common:button.cancel')}</Button>
                            </Fragment>
                        </div>
                    </Modal>
                )
            }


            {(showMove || showMoveMultiple) && (
                <Modal closeModal={() => {setShowMove(false); setShowMoveMultiple(false)}}>
                    <MoveToClass
                        schoolId={schoolId}
                        student={showMove}
                        studentIds={selectedStudents}
                        closeMove={() => {setShowMove(null); setShowMoveMultiple(false); setSelectedStudents([])}}
                        classId={classId}
                    />
                </Modal>
            )}

            {formErrors.length !== 0 && <FormErrors errors={formErrors.map(error => t(error))} />}
        </div>
    )
}

export default StudentsList;

import React, { useState, Fragment } from 'react';
import { Button, Loader, FormErrors } from '@axeedge/go-teacher-components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_CLASSES_LIST_QUERY, GET_CLASS_QUERY, GET_UNASSIGNED_STUDENTS_QUERY } from '../../../../services/graphql';
import { MOVE_STUDENT_TO_CLASS, MOVE_STUDENTS_TO_CLASS } from '../../services/graphql';

import { useTranslation } from 'react-i18next';

const MoveToClass = ({ closeMove, student, studentIds, schoolId, classId }) => {

    const { t } = useTranslation(['classes', 'common']);
    const [newClass, setNewClass] = useState(null);
    const [formErrors, setFormErrors] = useState([]);

    const { data, error, loading } = useQuery(GET_CLASSES_LIST_QUERY, {
        variables: {
            schoolId: schoolId
        }
    });

    const studentQueries = () => {
        if (classId) {
            return [{
                query: GET_CLASS_QUERY,
                variables: {
                    id: classId
                }
            }]
        } else {
            return [{
                query: GET_UNASSIGNED_STUDENTS_QUERY,
                variables: {
                    schoolId: schoolId
                }
            }]
        }
    }

    const [moveStudentToClass, { loading: moving }] = useMutation(MOVE_STUDENT_TO_CLASS, {
        update: (_, { data }) => {
            if (data.moveStudentToClass.errors && data.moveStudentToClass.errors.length !== 0) {
                setFormErrors(data.moveStudentToClass.errors);
                return;
            }
            if (data.moveStudentToClass.student.id) {
                closeMove();
                setNewClass(null);
            }
        },
        refetchQueries: () => studentQueries()
    });

    const [moveStudentsToClass] = useMutation(MOVE_STUDENTS_TO_CLASS, {
        update: (_, { data }) => {
            if (data.addExistingStudentsToClass.errors && data.addExistingStudentsToClass.errors.length !== 0) {
                setFormErrors(data.addExistingStudentsToClass.errors);
                return;
            }
            if (data.addExistingStudentsToClass.students) {
                closeMove();
                setNewClass(null);
            }
        },
        refetchQueries: () => studentQueries()
    });

    if (error) {
        return <p>{error.message}</p>
    }

    return (
        <Fragment>
            <h2>{t('move_student_to_class', {studentName: student?.name})}</h2>
            {loading && <Loader />}
            {data && data.school && (
                <Fragment>
                    <p className='u-m-base-2'>{t('select_new_class_from_dropdown')}:</p>
                    <select className='basic-form__text-select u-m-base-2' onChange={(e) => setNewClass(e.target.value !== 'none' ? e.target.value : null)}>
                        <option value="none">{t('please_select')}</option>
                        {data.school.classes
                            .filter(kls => kls.id !== classId)
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(kls => <option value={kls.id} key={`moveclass-${kls.id}`}>{kls.name}</option>)
                        }
                    </select>
                    {formErrors.length !== 0 && <FormErrors errors={formErrors.map(error => t(error))} />}

                    <Button
                        disabled={!newClass || moving}
                        className='u-m-right-2'
                        onClick={() => {
                            student ? moveStudentToClass({
                                variables: {
                                    studentId: student.id,
                                    studentsClassId: newClass,
                                    fromStudentsClassId: classId ? classId : null
                                }
                            }) : moveStudentsToClass({
                                variables: {
                                    studentsClassId: newClass,
                                    studentIds
                                }
                               
                            })
                        }}
                    >{moving ? t('loading') : t('move')}</Button>
                    <Button outline onClick={() => {
                        closeMove();
                        setNewClass(null);
                    }}>{t('common:button.cancel')}</Button>
                </Fragment>
            )}
        </Fragment>
    );
}

export default MoveToClass;
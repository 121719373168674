import React, {useState, useEffect} from 'react'
import PageHeader from '../../../components/PageHeader';
import { Button, Modal } from '@axeedge/go-teacher-components';
import {Users} from 'react-feather'
import styles from '../NewYear.module.scss'
import { CheckCircle } from 'react-feather';
import cx from 'classnames'
import AddStudent from '../../Students/components/AddStudent';
import UploadClassesPupils from '../../Classes/components/UploadClassesPupils';
import { getYearGroupLabel } from '../../../services/utils';

const Step5 = ({setCurrentStep, school}) => {

    const [showUpload, setShowUpload] = useState(false)
    const [uploadComplete, setUploadComplete] = useState(false)
    const [showAdd, setShowAdd] = useState(false)
    const [addedStudents, setAddedStudents] = useState([])

    const onUploadDone = () => {
        setShowUpload(false)
        setUploadComplete(true)
    }

    const onAdded = (s) => {
        setAddedStudents([...addedStudents, s])
    }

    return (
        <>
            <div className={styles.stepContainer}>
                <PageHeader title={`4 New Cohort`} icon={Users} close={() => setCurrentStep(4)} showBackLink={false} />
                <div className={styles.step5Inner}>
                    {showUpload ?
                    <UploadClassesPupils exit={() => { setShowUpload(false) }} isOnboarding={false} onUploadDone={onUploadDone} school={school} />
                    :
                        <>
                            <p className="u-m-base-2">And finally import or create the accounts for your new joiners.</p>
                            <p className="u-m-base-2"><strong>Please note: Only add new pupils, not the entire school</strong></p>
                            <p>If you are adding a whole class, we suggest using the Excel upload. Please ensure your spreadsheet is set up like this, or <a href="https://boomapps.s3.eu-west-2.amazonaws.com/sample-pupils-class-upload.xlsx" target='blank' className="link-underline u-text-primary heavy">download our template</a>.</p>
                            
                            <p className="u-m-base-2">If you are adding one or two new students, we suggest adding them individually.</p>
                            <div>
                                <Button onClick={() => setShowUpload(true)} className="u-m-right-2">Upload Students</Button>
                                <Button onClick={() => setShowAdd(true)}>Add Individual</Button>
                            </div>
                            {uploadComplete && <p className={cx('u-text-primary u-m-top-2', styles.successPara)} ><CheckCircle size="20" className='u-m-right-1'/> Students uploaded.</p>}
                            {addedStudents.length > 0 &&
                                <>
                                    <h2 className="u-m-top-2 u-m-base-1">Added Students</h2>
                                    <ul> 
                                    {addedStudents.map(s => {
                                        return (<li key={`s${s.id}`} className={styles.addedStudent}><span className={styles.yGroup}>{getYearGroupLabel(s.yearGroup)}</span><strong>{s.firstName} {s.lastName}</strong> {s.upn && <span className={styles.upn}>{s.upn}</span>}</li>)
                                    })}
                                    </ul>
                                </>
                            }
                        </>
                    }
                </div>
            </div>
            {!showUpload &&
                <div className={styles.stepFooter}>
                    <Button onClick={() => setCurrentStep(99)}>Next</Button>
                </div>
            }
            {showAdd &&
                <Modal closeModal={() => setShowAdd(false)}>
                    <AddStudent source="NewYear" schoolId={school.id} onAdded={onAdded}  />
                </Modal>
            }
        </>
    )
}

export default Step5